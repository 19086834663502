import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loader = new Subject<any>();
constructor() { }

showLoader = (value: any) => {
  this.loader.next(value);
}

getLoader(): Observable<any>  {
  return this.loader.asObservable();
}

}